<template>
    <div class="add-box">
        <i-header :title="config['title'] ? config['title'].template : ''" @back-event="toBack"></i-header>
        <div class="cnt-box">
            <i-form ref="form" @submit="onSubmit" scroll-to-error>
                <iFormItem v-for="(item, index) in template" :key="index"
                           :model-val="formData[item.controlName]"
                           :formTemplate="[template]"
                           :formData="formData"
                           @callBack="formCallBack"
                           :form-item="item">
                </iFormItem>
                <br />
                <i-button :loading="loading" :disabled="loading"
                          loading-text="提交" block
                          round type="primary" size="normal">
                    提交
                </i-button>
            </i-form>
        </div>
    </div>
</template>

<script>
import iFormItem from "@/components/iFormItem/iFormItem";
import generic from "../../utils/generic";

export default {
    name: "Add",
    components: {
        iFormItem,
    },
    data() {
        return {
            loading: false,
            template: [],
            formData: {},
            config: {},
            stashTemplate: {}
        };
    },
    created() {
        this.getConfig()
    },
    methods: {
        getConfig() {
            generic.getConfigs({
                    miceId:this.$route.query.miceId,
                    group: this.$route.query.type,
                    router: this.$route.path }, this.$smartStorage.get('miceInfo'))
                .then((config) => {
                    this.config = config
                    for (const configKey in config) {
                        if(config[configKey].callback) this[config[configKey].callback]()
                    }
                });
        },
        toBack() {
            this.$router.back();
        },
        toSubmit() {
            this.$refs.form.submit();
        },
        onSubmit(value) {
            // 排除选择的原始值
            for (const valKey in value) {
                if (valKey == "undefined") delete value[valKey];
                if (value[valKey + "SourceData"] !== undefined) {
                    delete value[valKey + "SourceData"];
                }
            }
            let context = this.jsonCopy(value)
            let checkExpr = this.checkList.filter((item) => {
                return eval(item.expr)
            })
            let sign = true
            if(checkExpr.length > 0) {
                checkExpr.map((val) => {
                    val.rules.map((v) => {
                        if(eval(v.expr)) {
                            this.$idialog
                                .alert({
                                    title: "提示",
                                    message: v.errorMsg,
                                })
                                .then(() => {
                                });
                            sign = false
                        }
                    })
                })
            }
            if(!sign) return false
            let _config = generic.replaceEvalObject(this.config['create'],Object.assign(value,
                { miceId: this.$route.query.miceId, subType: this.$route.query.subType }))
            this.$service.GenericPost(_config).then((res) => {
                if(res && res.success) {
                    this.$itoast("添加成功！")
                    this.$router.back()
                }
            })
        },
        async queryFormTemplate() {
            let _config = generic.replaceEvalObject(this.config['formTemplate'])
            const res = await this.$service.GenericPost(_config);

            if (res && res.success && res.content) {
                this.stashTemplate = res.content.template;
                let arr = []
                res.content.template.map((element) => {
                    let tmp = generic.replaceEvalObject(element,
                        {Status: this.$smartStorage.get('miceInfo')?.Status || '', path: this.$route.path})
                    arr.push(tmp)
                    this.$set(this.formData, element.controlName, element.defaultVal || '');
                    return element;
                });
                this.checkList = res.content.checkList || []

                this.template = arr
            }
        },
        formCallBack(value, index, formItem) {
            // 表单数据赋值
            if (value) {
                this.formData[formItem.controlName] = value.val || value.value || value[formItem.valName] || value;
            } else {
                this.formData[formItem.controlName] = "";
            }
            // 执行表单配置功能
            if (formItem.needFilterTemplate) {
                this.formVisible(value, index, formItem)
            }
        },
        formVisible(value, index, formItem) {
            // 根据表单项的值控制其他表单项的显隐藏
            let context = this.formData;
            console.log("====formitem的callback", formItem.controlName, value);
            this.template = this.stashTemplate.filter((item) => {
                if (item.visibleRuleExpr) {
                    // 命中规则才显示
                    try {
                        return item.visibleRuleExpr.every((subItem) => eval(subItem));
                    } catch (error) {
                        console.log(error);
                    }
                } else {
                    return true;
                }
            });
        }
    }
};
</script>

<style lang="less" scoped>
.add-box {
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    .cnt-box {
        padding: 0 0.18rem 0.2rem 0.18rem;
        box-sizing: border-box;
    }
    .submit-btn {
        width: 88%;
        height: 0.45rem;
        line-height: 0.45rem;
        border-radius: 0.3rem;
        margin: 11.73vw auto 0 auto;
        font-size: 0.15rem;
        background: var(--themeColor);
        display: block;
        color: #fff;
        text-align: center;
    }
}
</style>
